<template>
    <r-e-dialog title="编辑单价" :visible="dialogVisible" show-footer width="780px" @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel" @close="handleCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="房间" prop="name">
                <el-input disabled v-model="formPublish.name"/>
            </el-form-item>
            <el-form-item label="房型" prop="publishUuid">
                <el-select v-model="formPublish.publishUuid" placeholder="请选择房型" style="width: 100%;">
                    <el-option v-for="item in publishList" :key="item.uuid" :label="item.templateName"
                               :value="item.uuid"/>
                </el-select>
            </el-form-item>
            <el-form-item label="面积" prop="outsideArea">
                <el-input type="number" disabled v-model="formPublish.outsideArea">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <el-form-item label="月租金" prop="monthAmount">
<!--              :disabled="!getButtonMenu('EDIT_PRICE')"-->
                <el-input v-model="formPublish.monthAmount"  :disabled="true" >
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
<!--            <div class="flex">-->
<!--                <el-form-item label="半年租金折扣" prop="sixMonthDiscount">-->
<!--                    <el-input type="number" v-model="formPublish.sixMonthDiscount">-->
<!--                        <template slot="append">%</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="半年租金">-->
<!--                    <el-input disabled type="number" v-model="amountData.halfRentAmount">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--            </div>-->
<!--            <div class="flex">-->
<!--                <el-form-item label="半年押金">-->
<!--                    <el-input disabled type="number" v-model="amountData.halfBoneAmount">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="半年定金">-->
<!--                    <el-input disabled type="number" v-model="amountData.halfDing">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--            </div>-->
<!--            <div class="flex">-->
<!--                <el-form-item label="年租金折扣" prop="yearDiscount">-->
<!--                    <el-input type="number" v-model="formPublish.yearDiscount">-->
<!--                        <template slot="append">%</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="年租金">-->
<!--                    <el-input disabled type="number" v-model="amountData.yearRentAmount">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--            </div>-->
<!--            <div class="flex">-->
<!--                <el-form-item label="年押金">-->
<!--                    <el-input disabled type="number" v-model="amountData.yearBoneAmount">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="年定金">-->
<!--                    <el-input disabled type="number" v-model="amountData.yearDing">-->
<!--                        <template slot="append">元/月</template>-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
<!--            </div>-->
            <div>
                <el-form-item label="">
<!--                    <el-button type="primary" @click="getAmountData">计算金额</el-button>-->
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {apartmentDiscountAmountUpdate, apartmentDiscountAmountInfo, bzfGetAmount} from "@/api/house-resource";
import {MessageSuccess} from "@custom/message";
import {buttonCookies} from "@storage/cookies";

export default {
    name: "dialog-edit-detail-bzf-unit-price",
    data() {
        //自定义区间值校验
        let validatePass = (rule, value, callback) => {
            value = Number(value);
            if (value < this.percentageMin || value > this.percentageMax) {
                callback(new Error(`请输入${this.percentageMin}~${this.percentageMax}之间的数值`));
            } else if (value % 1 !== 0) {
                callback(new Error('请输入整数值'));
            } else {
                callback();
            }
        };
        return {
             percentageMin:null,
             percentageMax:null,
            dialogVisible: false,
            formPublish: {
                name: null,
                apartmentUuid: null,
              monthAmount: null,
                // sixMonthDiscount: null,
                // yearDiscount: null,
                publishUuid: null,
                outsideArea: null,
                insideArea: null,
            },
            rules: {
              monthAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
                // sixMonthDiscount: [
                //     {required: true, message: "请输入半年折扣", trigger: "blur"},
                //     {validator: validatePass, trigger: 'blur'}
                // ],
                yearDiscount: [
                    {required: true, message: "请输入年折扣", trigger: "blur"},
                    {validator: validatePass, trigger: 'blur'}
                ],
                publishUuid: [{required: true, message: "请选择房型", trigger: "change"},],
                outsideArea: [{required: true, message: "请输入建筑面积", trigger: "blur"},],
                insideArea: [{required: true, message: "请输入使用面积", trigger: "blur"},],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            publishList: [],
            amountData: {
                // halfBoneAmount: null,
                // halfDing: null,
                // halfRentAmount: null,
                // yearBoneAmount: null,
                // yearDing: null,
                // yearRentAmount: null,
            }
        };
    },
    methods: {
        //打开保租房编辑单价弹框
        async openDialog(data, publishList) {
            let that = this;
            //保存旧数据
            const oldFormPublish = that.formPublish;
            //保存房型
            this.publishList = publishList;
          // console.log(this.publishList,'this.publishList')
            //解构数据
            let {uuid, name, publishUuid,percentageMin,percentageMax} = data;
            this.percentageMax = percentageMax;
            this.percentageMin = percentageMin;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //调用接口获取，房间信息，房间金额
            let {info} = await apartmentDiscountAmountInfo({uuid});
            let { monthAmount, outsideArea, insideArea} = info;
            //金额转换
          monthAmount = ParseFloatFormat(monthAmount);

            //调用接口，获取房间所有计算后金额
            let res = await bzfGetAmount({uuid, monthAmount: null,  });
            //解构金额
            // let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount} = res.info;
            //金额转换
            // halfBoneAmount = ParseFloatFormat(halfBoneAmount);
            // halfDing = ParseFloatFormat(halfDing);
            // halfRentAmount = ParseFloatFormat(halfRentAmount);
            // yearBoneAmount = ParseFloatFormat(yearBoneAmount);
            // yearDing = ParseFloatFormat(yearDing);
            // yearRentAmount = ParseFloatFormat(yearRentAmount);
            //封装房间数据
            that.formPublish = {
                ...oldFormPublish, apartmentUuid: uuid, name, publishUuid,
              monthAmount, outsideArea, insideArea
            }
            //封装金额数据
            // that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
            loading.close();
            //打开弹窗
            this.dialogVisible = true;
        },

        //点击确认按钮事件
        handleSubmitPublish() {
            let that = this;
            // 表单校验
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    //解构数据
                    let {
                        apartmentUuid, monthAmount,  publishUuid, outsideArea,
                        insideArea
                    } = that.formPublish;
                    //金额转换
                  monthAmount = ParseIntFormat(monthAmount);
                    //封装数据
                    const params = {
                        apartmentUuid, monthAmount,  publishUuid, outsideArea,
                        insideArea
                    }
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});

                    //调用接口，编辑保租房房间单价
                    apartmentDiscountAmountUpdate(params).then(res => {
                        MessageSuccess("编辑成功");
                        //调用取消按钮事件，关闭弹框
                        that.handleCancel();
                    }).finally(() => loading.close());
                } else {
                    return false;
                }
            });
        },
        //点击取消按钮事件
        handleCancel() {
            //清空表单
            this.$refs["formPublish"].resetFields();
            // 取消、关闭弹窗
            this.dialogVisible = false;
            //调用上层方法，刷新房间信息
            this.$emit("handleSearch");
        },

        //点击计算金额事件
        async getAmountData() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            //解构数据，获取请求参数
            let {apartmentUuid: uuid, monthAmount, } = that.formPublish;
            //金额转换
          monthAmount = ParseIntFormat(monthAmount);
            //调用接口，获取房间所有计算后金额
            let res = await bzfGetAmount({uuid, monthAmount,  }).finally(() => loading.close());
            if(res){
                //解构金额
                // let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount} = res.info;
                //金额转换
                // halfBoneAmount = ParseFloatFormat(halfBoneAmount);
                // halfDing = ParseFloatFormat(halfDing);
                // halfRentAmount = ParseFloatFormat(halfRentAmount);
                // yearBoneAmount = ParseFloatFormat(yearBoneAmount);
                // yearDing = ParseFloatFormat(yearDing);
                // yearRentAmount = ParseFloatFormat(yearRentAmount);
                //封装金额数据
                // that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
            }
        },
        //获取按钮权限数组
        getButtonMenu(menu) {
            return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
        },
    }
}
</script>

<style scoped>

</style>